<template>
    <div class="px-4 mt-6"> 
        <h1 class="text-2xl font-medium mb-4">{{ title }}</h1>
        <div id="data-list-list-view" class="data-list-container">
        <vs-table
            ref="table"
            :sst="true"
            :total="totalDocs"
            :data="data"
            :max-items="dataTableParams.limit"
            :noDataText="noDataText"
            @change-page="handleChangePage"
        >
            <div slot="header" class="flex items-center justify-between mb-4">
                <vs-select
                    placeholder="10"
                    vs-multiple
                    autocomplete
                    v-model="dataTableParams.limit"
                    class="mr-6"
                >
                <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item,index) in limitOptions"
                    :clearable="false"
                />
                </vs-select>
            </div>

            <template slot="thead">
                <vs-th>User/Clinic Name</vs-th>
                <vs-th>Method</vs-th>
                <vs-th>Type</vs-th>
                <vs-th>Updated Fields</vs-th>
                <vs-th>Created/Updated By</vs-th>
                <vs-th>Timestamp</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" class="self-center">
                    <vs-td>
                    {{ data[indextr].name || data[indextr].updater }} 
                    </vs-td>
                    <vs-td >
                      <span class="uppercase">{{ data[indextr].method || 'n/a' }} </span>
                    </vs-td>
                    <vs-td>
                      <span class="uppercase">{{ data[indextr].type || 'n/a' }} </span>
                    </vs-td>
                    <vs-td>
                      <div class="flex flex-col space-y-3">
                        <template v-if="data[indextr].updatedFields && data[indextr].updatedFields.length">
                            <span v-for="(field, index) in data[indextr].updatedFields[0]" :key="index">
                                <span class="font-medium">"{{ index }}" </span> from
                                <template v-if="field.oldValue !== undefined && field.newValue !== undefined">
                                  <span class="font-medium">{{field.oldValue}}</span> to <span class="font-medium">{{field.newValue}}</span>
                                </template>
                                <template v-else>
                                    {{ JSON.stringify(field) }}
                                </template>
                            </span>
                        </template>
                        <template v-else>N/A</template>
                    </div>
                    </vs-td>
                    <vs-td>
                    {{ data[indextr].updater || 'N/A' }} 
                    </vs-td>
                    <vs-td>
                    {{ formattedDate(data[indextr].createdAt) }}
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>

        <div class="mt-4">
            <span class="mr-2">
                {{
                dataTableParams.page * dataTableParams.limit -
                (dataTableParams.limit - 1)
                }}
                -
                {{
                totalDocs - dataTableParams.page * dataTableParams.limit > 0
                ? dataTableParams.page * dataTableParams.limit
                : totalDocs
                }}
                of {{ totalDocs }}
            </span>
            <div class="pagination-div" v-if="serverResponded">
                <paginate
                :page-count="totalPage"
                :click-handler="handleChangePage"
                class="pagination"
                :page-range="9"
                :prevText="'<'"
                :nextText="'>'"
                ></paginate>
            </div>
        </div>

        </div>
    </div>
  </template>

<script>
import vSelect from "vue-select";

export default {
  name: "LogsList",
  components: {
    "v-select": vSelect
  },
  props: ["data", "totalDocs", "page", "noDataText", "title"],
  data() {
    return {
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "-1",
        page: 1,
        limit: 10
      },
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 }
      ],
      serverResponded: false
    };
  },
  methods: {
    async fetchList() {
     this.$emit("fetchList", this.dataTableParams);
     this.serverResponded = true;
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.fetchList();
    },
    formattedDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      });
    },
  },
  watch: {
    "dataTableParams.limit": function(newlimit, oldLimit) {
        if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.fetchList();
        }
    }
  },
  computed: {
    totalPage: function() {
        return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    }
  },
  created() {
    this.noDataText = "Loading...";
    this.fetchList();
  }
};
</script>
