<template>
      <LogsList
        :title="title"
        :data="data"
        :totalDocs="totalDocs"
        :page="page"
        :noDataText="noDataText"
        @fetchList="getLogs"
    />
  </template>
  
  <script>
  import { mapActions } from "vuex";
  import LogsList from "../../components/account-creation-logs/list";
  
  export default {
    components: {
      LogsList
    },
    data() {
      return {
        totalDocs: 0,
        page: 1,
        data: [],
        noDataText: 'Loading...',
        title: "Account Update/Creation Logs"
      };
    },
    methods: {
        ...mapActions("appUser", ["fetchAccountCreationLogs"]),
  
      async getLogs(params) {
        let self = this;
        this.fetchAccountCreationLogs(params).then(res => {
          self.data = res.data.data.docs;
          self.totalDocs = res.data.data.pagination.total || 0;
          self.page = res.data.data.pagination.page;
          self.noDataText = 'No Logs Available'
        });
      }
    }
  }
  </script>
  